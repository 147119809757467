import React, {
  useContext,
  useMemo,
  useReducer,
  useCallback,
  useEffect,
} from 'react';
import { useSession } from 'next-auth/react';
import * as amplitude from '@amplitude/analytics-browser';
import Cookies from 'js-cookie';

import { isServer } from 'utils/isServer';
import { loadRecords } from 'utils/JSONAPIAdapter';
import { fetchLocation } from 'utils/location';

type UserContextValue = {
  user: UserModel | null;
  userLocation: UserLocation | null;
  proUpgradeSidebarOpen: boolean;
  setUser: (user: UserModel | null) => void;
  refreshUser: () => void;
  toggleProUpgradeSidebar: (isOpen: boolean) => void;
  loading: boolean;
};

type UserContextAction =
  | {
      type: 'UPDATE_USER';
      payload: null | UserModel;
    }
  | {
      type: 'SET_USER_LOCATION';
      payload: UserLocation | null;
    }
  | {
      type: 'TOGGLE_PROUPGRADE_SIDEBAR';
      payload: boolean;
    };

const userContextReducer = (
  state: {
    user: UserModel | null;
    userLocation: UserLocation | null;
    loading: boolean;
    proUpgradeSidebarOpen: boolean;
  },
  action: UserContextAction
) => {
  switch (action.type) {
    case 'UPDATE_USER':
      // Set cookie to exclude PRO users from Unbounce popups
      if (action.payload?.pro) {
        Cookies.set('thedyrt-pro-user', '1', {
          path: '/',
        });
      } else if (action.payload === null) {
        Cookies.remove('thedyrt-pro-user', { path: '/' });
      }
      return {
        user: action.payload,
        userLocation: state.userLocation,
        loading: false,
        proUpgradeSidebarOpen: state.proUpgradeSidebarOpen,
      };

    case 'SET_USER_LOCATION':
      return {
        user: state.user,
        userLocation: action.payload,
        loading: state.loading,
        proUpgradeSidebarOpen: state.proUpgradeSidebarOpen,
      };

    case 'TOGGLE_PROUPGRADE_SIDEBAR':
      return {
        user: state.user,
        userLocation: state.userLocation,
        loading: state.loading,
        proUpgradeSidebarOpen: action.payload,
      };

    default:
      return state;
  }
};

const UserContext = React.createContext<UserContextValue>({
  user: null,
  userLocation: null,
  proUpgradeSidebarOpen: false,
  setUser: () => {},
  toggleProUpgradeSidebar: () => {},
  loading: true,
  refreshUser: () => {},
});

// Provides { user, setUser, userLocation } interface for reading and updating the user
export const useUser = (): UserContextValue => useContext(UserContext);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data: sessionData, status } = useSession();
  const loading = status === 'loading';

  const [userState, userDispatch] = useReducer(userContextReducer, {
    user: null,
    userLocation: null,
    loading: true,
    proUpgradeSidebarOpen: false,
  });

  useEffect(() => {
    amplitude.setUserId(sessionData?.user?.id || undefined);
  }, [sessionData?.user?.id]);

  const setUser = useCallback(
    (user: UserModel | null) => {
      userDispatch({
        type: 'UPDATE_USER',
        payload: user,
      });
    },
    [userDispatch]
  );

  const toggleProUpgradeSidebar = useCallback(
    (isOpen: boolean) => {
      userDispatch({
        type: 'TOGGLE_PROUPGRADE_SIDEBAR',
        payload: isOpen,
      });
    },
    [userDispatch]
  );

  const refreshUser = useCallback(async () => {
    const token = sessionData?.accessToken;

    if (token) {
      const { data } = await loadRecords<UserModel>(
        'users',
        {
          filter: {
            current: '1',
          },
          time: Date.now(),
        },
        {
          'x-access-token': token as string,
          Authorization: `Bearer ${token}` as string,
        }
      );
      if (data?.length) {
        userDispatch({
          type: 'UPDATE_USER',
          payload: data[0],
        });
      }
    }
  }, [sessionData]);

  const userContextValue = useMemo(
    () => ({
      user: userState.user,
      userLocation: userState.userLocation,
      loading: userState.loading,
      proUpgradeSidebarOpen: userState.proUpgradeSidebarOpen,
      setUser,
      toggleProUpgradeSidebar,
      refreshUser,
    }),
    [userState, setUser, toggleProUpgradeSidebar, refreshUser]
  );

  const setUserLocation = useCallback(async () => {
    const userLocation = await fetchLocation();
    userDispatch({
      type: 'SET_USER_LOCATION',
      payload: userLocation,
    });
  }, [userDispatch]);

  useEffect(() => {
    if (!isServer) {
      setUserLocation();
    }
  }, [setUserLocation]);

  useEffect(() => {
    if (loading) return;

    const user = sessionData?.user as UserModel | undefined;
    if (user && user.id) {
      setUser(user);
    } else {
      setUser(null);
    }
  }, [sessionData, setUser, loading]);

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};

import React from 'react';
import dynamic from 'next/dynamic';

import { useOverlayContext } from 'hooks/useOverlayContext';

const SeeInAppModal = dynamic(
  () => import('components/AppModal/SeeInAppModal'),
  {
    ssr: false,
  }
);
const _SidebarFiltersModal = dynamic(
  () => import('components/SearchCampgrounds/_SidebarFiltersModal'),
  {
    ssr: false,
  }
);

const SitesAboutModal = dynamic(
  () => import('components/AppFormCollection/Site/SitesAboutModal'),
  {
    ssr: false,
  }
);
const AppMediaViewer = dynamic(
  () => import('components/AppMediaViewer/AppMediaViewer'),
  {
    ssr: false,
  }
);
const DyrtAlertsProUpgrade = dynamic(
  () => import('components/AppModal/_DyrtAlertsProUpgrade'),
  {
    ssr: false,
  }
);
const AppCampgroundGatingModal = dynamic(
  () => import('components/AppModal/AppCampgroundGatingModal'),
  {
    ssr: false,
  }
);
const AppConfirmationModal = dynamic(
  () => import('components/AppModal/AppConfirmationModal'),
  {
    ssr: false,
  }
);
const AppDeleteReviewModal = dynamic(
  () => import('components/AppModal/AppDeleteReviewModal'),
  {
    ssr: false,
  }
);
const AppReportModal = dynamic(
  () => import('components/AppModal/AppReportModal'),
  {
    ssr: false,
  }
);
const CampgroundAddedModal = dynamic(
  () => import('components/AppModal/CampgroundAddedModal'),
  {
    ssr: false,
  }
);
const ContactBookingModal = dynamic(
  () => import('components/AppModal/ContactBookingModal'),
  {
    ssr: false,
  }
);
const ProCellServiceOverlay = dynamic(
  () => import('components/AppModal/ProCellServiceModal'),
  {
    ssr: false,
  }
);

const _ProUpgradeModalB = dynamic(
  () =>
    import(
      'components/AppModal/ProUpgradeModal/_ProUpgradeModalB/_ProUpgradeModalB'
    ),
  {
    ssr: false,
  }
);

const _ProUpgradeModalC = dynamic(
  () =>
    import(
      'components/AppModal/ProUpgradeModal/_ProUpgradeModalC/_ProUpgradeModalC'
    ),
  {
    ssr: false,
  }
);
const _ProUpgradeModalD = dynamic(
  () =>
    import(
      'components/AppModal/ProUpgradeModal/_ProUpgradeModalD/_ProUpgradeModalD'
    ),
  {
    ssr: false,
  }
);

const CampgroundAboutModal = dynamic(
  () => import('components/AppModal/CampgroundAboutModal'),
  {
    ssr: false,
  }
);

const InterstitialModal = dynamic(
  () => import('components/AppModal/InterstitialModal'),
  {
    ssr: false,
  }
);

const AppShareModal = dynamic(
  () => import('components/AppModal/AppShareModal'),
  {
    ssr: false,
  }
);

const AppSaveList = dynamic(() => import('components/AppModal/AppSaveList'), {
  ssr: false,
});

const InternalBookingModal = dynamic(
  () => import('components/ManagerCalendar/InternalBookingModal'),
  { ssr: false }
);

const ExternalBookingModal = dynamic(
  () => import('components/ManagerCalendar/ExternalBookingModal'),
  { ssr: false }
);
const EditBlockedDateRangeModal = dynamic(
  () => import('components/ManagerCalendar/EditBlockedDateRangeModal'),
  { ssr: false }
);

const ManagerCalendarBlockDatesModal = dynamic(
  () => import('components/ManagerCalendar/CreateBlockedDateRangeModal'),
  { ssr: false }
);

const AppDatesSelectModal = dynamic(
  () => import('components/AppModal/AppDatesSelectModal'),
  { ssr: false }
);

const SignInSignUpModal = dynamic(
  () => import('components/AppModal/SignInSignUpModal'),
  { ssr: false }
);

const _Modify = dynamic(
  () => import('components/ManagerReservationsModal/_Modify'),
  { ssr: false }
);

const _Decline = dynamic(
  () => import('components/ManagerReservationsModal/_Decline'),
  { ssr: false }
);

const CampgroundConnectionDetailsModal = dynamic(
  () =>
    import(
      'components/CampgroundConnectionDetailsModal/CampgroundConnectionDetailsModal'
    ),
  { ssr: false }
);
const ClaimAddListingSuccessModal = dynamic(
  () =>
    import(
      'components/ClaimAddListingSuccessModal/ClaimAddListingSuccessModal'
    ),
  { ssr: false }
);

const ClaimFormSuccessModal = dynamic(
  () => import('components/ClaimFormSuccessModal/ClaimFormSuccessModal'),
  { ssr: false }
);

const _Approve = dynamic(
  () => import('components/ManagerReservationsModal/_Approve'),
  { ssr: false }
);

const _Cancel = dynamic(
  () => import('components/ManagerReservationsModal/_Cancel'),
  { ssr: false }
);

const SetupBookingsStandbyModal = dynamic(
  () =>
    import('components/SetupBookingsStandbyModal/SetupBookingsStandbyModal'),
  { ssr: false }
);

const SetupBookingsSuccessModal = dynamic(
  () =>
    import('components/SetupBookingsSuccessModal/SetupBookingsSuccessModal'),
  { ssr: false }
);

const LocationEditSuccessModal = dynamic(
  () => import('components/LocationEditSuccessModal/LocationEditSuccessModal'),
  { ssr: false }
);

const AppOverlay = () => {
  const { overlays } = useOverlayContext();
  return (
    <>
      {overlays.map(({ type }, index) => {
        switch (type) {
          case 'about':
            return <CampgroundAboutModal key={index} />;
          case 'add':
            return <AppCampgroundGatingModal key={index} />;
          case 'claim-success':
            return <ClaimFormSuccessModal key={index} />;
          case 'confirmation':
            return <AppConfirmationModal key={index} />;
          case 'delete':
            return <AppDeleteReviewModal key={index} />;
          case 'dyrt-alerts-pro-upgrade':
            return <DyrtAlertsProUpgrade key={index} />;
          case 'interstitial-modal':
            return <InterstitialModal key={index} />;
          case 'listing-success':
            return <ClaimAddListingSuccessModal key={index} />;
          case 'listing-success-camper':
            return <CampgroundAddedModal key={index} />;
          case 'location-edit-success':
            return <LocationEditSuccessModal key={index} />;
          case 'manager-block-dates-create':
            return <ManagerCalendarBlockDatesModal key={index} />;
          case 'manager-block-dates-edit':
            return <EditBlockedDateRangeModal key={index} />;
          case 'manager-external-booking':
            return <ExternalBookingModal key={index} />;
          case 'manager-internal-booking':
            return <InternalBookingModal key={index} />;
          case 'manager-reservation-approve':
            return <_Approve key={index} />;
          case 'manager-reservation-decline':
            return <_Decline key={index} />;
          case 'manager-reservation-modify':
            return <_Modify key={index} />;
          case 'manager-reservation-cancel':
            return <_Cancel key={index} />;
          case 'media':
            return <AppMediaViewer key={index} />;
          case 'report':
            return <AppReportModal key={index} />;
          case 'save':
            return <AppSaveList key={index} />;
          case 'share':
            return <AppShareModal key={index} />;
          case 'stay-connected':
            return <CampgroundConnectionDetailsModal key={index} />;
          case 'see-in-app':
            return <SeeInAppModal key={index} />;
          case 'setup-bookings-success':
            return <SetupBookingsSuccessModal key={index} />;
          case 'setup-bookings-standby':
            return <SetupBookingsStandbyModal key={index} />;
          case 'sidebar-filters':
            return <_SidebarFiltersModal key={index} />;
          case 'sign-in-sign-up':
            return <SignInSignUpModal key={index} />;
          case 'date-range-picker':
            return <AppDatesSelectModal key={index} />;

          case 'sites-about':
            return <SitesAboutModal key={index} />;
          case 'contact-booking':
            return <ContactBookingModal key={index} />;
          case 'pro-cell-service':
            return <ProCellServiceOverlay key={index} />;
          case 'pro-upgrade-modal-b':
            return <_ProUpgradeModalB key={index} />;
          case 'pro-upgrade-modal-c':
            return <_ProUpgradeModalC key={index} />;
          case 'pro-upgrade-modal-d':
            return <_ProUpgradeModalD key={index} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default AppOverlay;

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { JSONApiErrorError } from 'utils/errors';
import { devLog } from 'utils/log';

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
const release = process.env.NEXT_PUBLIC_SENTRY_RELEASE;
const environment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;

Sentry.init({
  dsn,
  release,
  environment,
  beforeSend: (event, hint) => {
    // returning null will drop the event
    if (
      hint?.originalException
        ?.toString()
        ?.includes('elem.matches is not a function') // This is being thrown by airtory, the video ad provider. WEB-10334
    ) {
      return null;
    }
    if (
      event.exception?.values?.[0]?.value?.includes(
        'Non-Error promise rejection captured'
      )
      // Thrown by an ad script. WEB-10225
    ) {
      devLog('Suppressed Sentry event', event);
      return null;
    }
    if (
      (hint?.originalException as JSONApiErrorError)?.name ===
      'JSONApiErrorError'
    ) {
      const { status, errors } = hint?.originalException as JSONApiErrorError;
      if (status == 404) {
        return null;
      }
      event.fingerprint = [
        String(status),
        ...errors.map((error) => error.title),
      ];
    }

    if (event.environment === 'development') {
      console.error(
        'SENTRY EVENT CAPTURED',
        JSON.stringify(hint?.originalException, null, 2)
      );
      return null;
    }

    // Suppress Stripe errors on pages that do not have a <StripeProvider>
    const url = String(event.tags?.url);
    const matchersForPagesThatUseStripe = [
      RegExp('alerts(?!/my-alerts)'),
      RegExp('checkout'),
    ];
    if (
      event.message?.match('Stripe.js') &&
      !matchersForPagesThatUseStripe.some((matcher) => url.match(matcher))
    ) {
      return null;
    }
    return event;
  },
});

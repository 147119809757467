import { devLog } from './log';

export function removeSlot(slot: googletag.Slot | null) {
  try {
    if (slot) {
      const { googletag } = window;
      googletag?.cmd?.push(function () {
        devLog('removing slot' + slot?.getSlotElementId());
        googletag.destroySlots([slot]);
      });
    }
  } catch (error) {
    console.error('Error removing ad slot', error);
  }
}

export function removeAllSlots() {
  try {
    const { googletag } = window;
    googletag?.cmd?.push(function () {
      devLog('removing all slots');
      googletag.destroySlots();
    });
  } catch (error) {
    console.error('Error removing all ad slots', error);
  }
}

export function displayAds(
  adType: DyrtAdType,
  sizes: ResponsiveAdSize[],
  divId: string
): googletag.Slot | null {
  if (typeof window === 'undefined') return null;
  try {
    devLog('initializing ' + divId);
    const currentViewportWidth = window.innerWidth;

    window.googletag = window.googletag || { cmd: [] };
    const { googletag } = window;
    let slot: googletag.Slot | null = null;
    const slotSizes: googletag.GeneralSize[] = [];
    sizes.forEach(({ adDimensions }) => {
      adDimensions.forEach((dimension) => {
        if (dimension === 'fluid') {
          slotSizes.push(dimension);
        }
        const dimensionAsNumber = Number(dimension[0]);
        if (dimensionAsNumber <= currentViewportWidth) {
          slotSizes.push(dimension);
        }
      });
    });
    googletag?.cmd?.push(function () {
      slot = googletag.defineSlot(
        `/22845402803/TheDyrt.com/${adType}`,
        // '/6355419/Travel/Europe', // Google's placeholder ad for testing
        slotSizes,
        divId
      );

      slot?.addService?.(googletag.pubads());

      if (slot && sizes[0]?.screenDimensions) {
        const mapping = googletag.sizeMapping?.();
        sizes.forEach(({ adDimensions, screenDimensions }) => {
          mapping.addSize(screenDimensions, adDimensions);
        });
        const mappingResult = mapping.build();
        if (mappingResult) {
          slot.defineSizeMapping(mappingResult);
        }
      }
      googletag.pubads?.().enableLazyLoad({
        fetchMarginPercent: 30, // fetches the ad when scroll position is .3 viewports away from the ad slot
        renderMarginPercent: 30, // renders the ad when scroll position is .3 viewports away from the ad slot
        mobileScaling: 1.5, // multiples the above values by 1.5 for small viewports
      });
      googletag.pubads?.().collapseEmptyDivs?.(); // Uncollapsed by default because we expect ads to be filled. See https://developers.google.com/publisher-tag/samples/collapse-empty-ad-slots
      googletag.enableServices?.();
      googletag.display?.(divId);
    });
    return slot;
  } catch (error) {
    console.error('Error displaying ads', error);
    return null;
  }
}

export function displayInterstitialAd(): googletag.Slot | null {
  try {
    window.googletag = window.googletag || { cmd: [] };

    let interstitialSlot: googletag.Slot | null = null;

    googletag?.cmd?.push(() => {
      interstitialSlot = googletag.defineOutOfPageSlot(
        '/22845402803/TheDyrt.com/Interstitial',
        // '/6355419/Travel/Europe/France/Paris', // This is Google's test ad unit
        googletag.enums.OutOfPageFormat.INTERSTITIAL
      );

      if (interstitialSlot) {
        // We can enable and display the ad here because we're not using single request architecture.
        googletag.enableServices();
        googletag.display(interstitialSlot.getSlotElementId());
        // logEvents();  // for testing
      }
    });
    return interstitialSlot;
  } catch (e) {
    console.error('Error setting up interstitial ad', e);
    return null;
  }
}
export const setDimensionsAndLogOnRender = (
  event: googletag.events.SlotRenderEndedEvent
) => {
  try {
    const slotId = event.slot.getSlotElementId();
    // if not in production, log the event
    if (window.location.host !== 'thedyrt.com') {
      const scrollPercent = Math.round(
        (100 * window.scrollY) / document.body.scrollHeight
      );
      console.log(
        'Rendered ',
        slotId,
        new Date().toLocaleString(),
        `at scroll position ${scrollPercent}%`,
        event
      );
    }
  } catch (error) {
    console.error('Error handling ad render', error);
  }
};

export const skipInterstitalAdParam = (skipInterstitalAd?: boolean) =>
  skipInterstitalAd ? { 'data-google-interstitial': 'false' } : {};

/* eslint-disable @typescript-eslint/no-explicit-any */

// Adds event listeners that could be used for debugging if needed.
export function logEvents() {
  try {
    const { googletag } = window;

    // This listener will be called when an impression is considered viewable.
    googletag.pubads().addEventListener('impressionViewable', (event: any) => {
      const slotId = event.slot.getSlotElementId();
      console.log('Impression has become viewable.', slotId);
    });

    // This listener will be called when a slot's creative iframe load event fires.
    googletag.pubads().addEventListener('slotOnload', (event: any) => {
      const slotId = event.slot.getSlotElementId();
      console.log('Creative iframe load event has fired.', slotId);
    });

    // This listener will be called when a slot has finished rendering.
    googletag.pubads().addEventListener('slotRenderEnded', (event: any) => {
      const slotId = event.slot.getSlotElementId();

      // Record details of the rendered ad.
      const details = {
        'Advertiser ID': event.advertiserId,
        'Campaign ID': event.campaignId,
        'Company IDs': event.companyIds,
        'Creative ID': event.creativeId,
        'Creative Template ID': event.creativeId,
        'Is backfill?': event.isBackfill,
        'Is empty?': event.isEmpty,
        'Label IDs': event.labelIds,
        'Line Item ID': event.lineItemId,
        Size:
          typeof event.size === 'string'
            ? event.size
            : event.size?.join('x') ?? null,
        'Slot content changed?': event.slotContentChanged,
        'Source Agnostic Creative ID': event.sourceAgnosticCreativeId,
        'Source Agnostic Line Item ID': event.sourceAgnosticLineItemId,
        'Yield Group IDs': event.yieldGroupIds,
      };

      console.log('Slot has finished rendering.', slotId, details);
    });

    // This listener will be called when the specified service actually
    // sets an ad request for a slot. Each slot will fire this event, even
    // though they may be batched together in a single request if single
    // request architecture (SRA) is enabled.
    googletag.pubads().addEventListener('slotRequested', (event: any) => {
      const slotId = event.slot.getSlotElementId();
      // requestedTimestamp[slotId] = Date.now();
      console.log('Slot has been requested.', slotId);
    });

    // This listener will be called when an ad response has been received for
    // a slot.
    googletag
      .pubads()
      .addEventListener('slotResponseReceived', (event: any) => {
        const slotId = event.slot.getSlotElementId();
        console.log('Ad response has been received.', slotId);
      });

    // This listener will be called whenever the on-screen percentage of an ad
    // slot's area changes.
    googletag
      .pubads()
      .addEventListener('slotVisibilityChanged', (event: any) => {
        const slotId = event.slot.getSlotElementId();

        // Record details of the event.
        const details = { 'Visible area': `${event.inViewPercentage}%` };

        console.log('Visibility has changed.', slotId, details);
      });
  } catch (error) {
    console.error('Error setting up ad event listeners', error);
  }
}

import { DEFAULT_MAP_CENTER } from 'constants/map';
import { USER_AGENT_HEADERS } from 'constants/userAgent';

const API_BASE = process.env.NEXT_PUBLIC_API_BASE;
const headers = USER_AGENT_HEADERS;

export async function fetchLocation(): Promise<UserLocation> {
  try {
    const data = await fetch(`${API_BASE?.split('/api')[0]}/edge/geoip`, { headers }).then(
      (response) => {
        return response.json();
      }
    );

    data.lat = +data.lat;
    data.long = +data.long;
    data.userLocationFound = true;
    return data as UserLocation;
  } catch (e) {
    return DEFAULT_MAP_CENTER;
  }
}

import { useEffect, useRef } from 'react';
import Router from 'next/router';
import { onLCP, onCLS, onFCP, onTTFB, onINP } from 'web-vitals';

import { logAmplitudePageView } from 'utils/amplitude';
import { devLog } from 'utils/log';

type WebVitals = {
  CLS: number | undefined;
  FCP: number | undefined;
  LCP: number | undefined;
  TTFB: number | undefined;
};

export const useLogAmplitudePageViewWithVitals = async () => {
  const webVitals = useRef<WebVitals | null>({
    CLS: undefined,
    FCP: undefined,
    LCP: undefined,
    TTFB: undefined,
  });

  useEffect(() => {
    // Set up listeners to gather web vitals
    onFCP((metric) => {
      webVitals.current && (webVitals.current.FCP = metric.value);
      devLog('FCP', metric);
    });
    onLCP(
      (metric) => {
        webVitals.current && (webVitals.current.LCP = metric.value);
        devLog('LCP', metric);
      },
      {
        reportAllChanges: true, // LCP doesn't get reported unless we use this option
      }
    );
    onCLS(
      (metric) => {
        webVitals.current && (webVitals.current.CLS = metric.value);
        devLog('CLS', metric);
      },
      {
        reportAllChanges: true, // CLS doesn't get reported unless we use this option
      }
    );
    onTTFB(async (metric) => {
      webVitals.current && (webVitals.current.TTFB = metric.value);
      devLog('TTFB', metric);
    });
    onINP((metric) => devLog('INP', metric), { reportAllChanges: true });

    const logPageViewAfterReadyStateComplete = async () => {
      if (document.readyState === 'complete') {
        // wait 1 second to ensure all web vitals are collected
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (webVitals.current) {
          devLog(
            'Logging Amplitude Web Page View with vitals',
            webVitals.current
          );
          logAmplitudePageView({ ...webVitals.current });
          webVitals.current = null;
        }
        document.removeEventListener(
          'readystatechange',
          logPageViewAfterReadyStateComplete
        );
      }
    };

    document.addEventListener(
      'readystatechange',
      logPageViewAfterReadyStateComplete
    );

    // For a SPA, web vitals are not updated after the initial load. So just log the page view without web vitals.
    Router.events.on('routeChangeComplete', logAmplitudePageView);
    return () => {
      Router.events.off('routeChangeComplete', logAmplitudePageView);
    };
  }, []);
};

export default useLogAmplitudePageViewWithVitals;
